import React, { useState } from 'react';
import './App.css';
import ImgLogo from './assets/img/Copy of StreamSlide (3).png';
import { LinearProgress } from '@mui/material';
import ExamplesSection from './ExamplesSection';


const examples = ExamplesSection();



function App() {
  const processDomain = "https://streamslide.io/processor";
  const [url, setUrl] = useState('https://www.youtube.com/watch?v=I_Wj_Yrgy8o');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null);
  const [conversionMessage, setConversionMessage] = useState('');
  const [videoSummary, setVideoSummary] = useState('');
  const summarizeURL = processDomain + '/video/summarize';
  const processURL = processDomain + '/video/process';

  async function fetchVideoSummary(videoUrl:string) {
    try {
      const startResponse = await fetch(summarizeURL, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ url: videoUrl })
      });

      if (!startResponse.ok) {
        throw new Error(`HTTP error! Status: ${startResponse.status}`);
      }

      const startData = await startResponse.json();
      console.log('Task started, Task ID:', startData.task_id);

      const checkTaskStatus = async (taskId:string) => {
        const statusResponse = await fetch(`${summarizeURL}/${taskId}`, {
          method: 'GET'
        });

        if (!statusResponse.ok) {
          throw new Error(`HTTP error! Status: ${statusResponse.status}`);
        }

        const statusData = await statusResponse.json();
        console.log('Current task status:', statusData);

        if (statusData.status === 'Pending') {
          setTimeout(() => checkTaskStatus(taskId), 2000);
        } else if (statusData.status === 'Failed') {
          console.error('Task failed:', statusData.error);
          setVideoSummary('Failed to load summary.');
        } else if (statusData.status === 'SUCCESS') {
          setVideoSummary(statusData.chat_response);
          if (pdfBlobUrl) {
            setIsLoading(false);
          }
        }
      };

      checkTaskStatus(startData.task_id);
    } catch (error) {
      console.error('Error fetching video summary:', error);
      setVideoSummary('Failed to load summary.');
    }
  }

  async function handleVideoAndSummary(url: string, email: string) {
    setIsLoading(true);
    const startTime = new Date();

    const processData = await startProcessing(url, email);
   
    await fetchVideoSummary(url);

    if (processData.pdf_path === undefined) {
      if (email) {
        await processResults(processData, startTime);
      }
    }
  }

  async function startProcessing(url: string, email: string) {
    const response = await fetch(processURL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ url, resolution: "720p", email })
    });

    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

    const data = await response.json();
    // await fetchVideoSummary(url);
    if(data.pdf_path !== undefined) {
      setPdfBlobUrl(`${processDomain}/${data.pdf_path.replace(/\s/g, '%20')}#toolbar=0&navpanes=0`);
      setConversionMessage("Process Completed! You can now download the PDF and view the summary.");
      setIsLoading(false);
      
    }

    return data;
  }

  async function checkStatus(taskId: string, url: string) {
    while (true) {
      try {
        const response = await fetch(`${url}/${taskId}`, { method: 'GET' });

        if (!response.ok) {
          throw new Error(`Task failed with status: ${response.status}`);
        }

        const data = await response.json();
        if (data.status === 'SUCCESS') {
          return data;
        } else if (['Failed', 'ERROR'].includes(data.status)) {
          throw new Error(`Task failed with status: ${data.status}`);
        }

        await new Promise(resolve => setTimeout(resolve, 5000));
      } catch (error) {
        if (error instanceof Error && error.name === "SyntaxError") {
          console.error("Failed to parse response as JSON:", error);
          throw new Error("Server error occurred. Please try again later.");
        } else {
          throw error;
        }
      }
    }
  }

  async function processResults(processData: any, startTime: Date) {
    const processPromise = checkStatus(processData.task_id, processURL);
    console.log(processPromise);

    Promise.all([processPromise]).then(([videoResult]) => {
      if (videoResult.pdf_path !== null && videoResult.pdf_path !== undefined) {
        
        const pdfUrl = `${processDomain}/${videoResult.pdf_path.replace(/\s/g, '%20')}`;

        setPdfBlobUrl(pdfUrl + "#toolbar=0&navpanes=0");
        setConversionMessage("Process Completed! You can now download the PDF and view the summary.");
        setIsLoading(false);
      }
    }).catch(error => {
      console.error('Error:', error);
      setConversionMessage(`Error: ${error.message}`);
    });
  }

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    setPdfBlobUrl(null);
    setConversionMessage('Please wait while our AI model extracts slides from the video, this may take a few minutes depending on the length of the video...');

    handleVideoAndSummary(url, email);
  };

  return (
    <main className="w-full h-full flex flex-col items-center justify-center bg-main bg-top">
      {/* <nav className="mt-10 mb-0">
        <ul className='flex gap-6'>
          <li>Home</li>
          <li>Features</li>
          <li>Pricing</li>
          <li>Contact</li>
        </ul>
      </nav> */}

      <section className='flex flex-col items-center mb-36'>
        <img src={ImgLogo} style={{width:'200px'}} />
        <h1 className="text-5xl font-bold text-dark-blue text-center mb-10">Transform YouTube Videos into <br />
          <em className='text-blue not-italic'>Interactive Slides</em>
        </h1>
        <div className='text-text-grey text-center text-3xl mb-10'>
          <p className="max-w-3xl mb-4">Summarize videos, automatically extract and convert slides from YouTube videos into a downloadable PDF.</p>
          <p>Perfect for educational content, presentations, and more.</p>
        </div>
        <form className='w-full flex-col px-10 mt-5 md:flex-row items-center flex gap-4 md:items-end justify-between' onSubmit={handleSubmit}>
          <div className='flex-1 w-full'>
            <label htmlFor="url" className='text-md font-medium text-dark-blue mb-2'>YouTube URL</label>
            <input
              className='block w-full rounded-md border-0 py-2 px-4 text-text-grey ring-1 ring-inset ring-light-blue placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dark-blue'
              type="text" id="url" placeholder='https://www.youtube.com/watch?v=I_Wj_Yrgy8o' value={url} onChange={(e) => setUrl(e.target.value)} required />
          </div>
          <div className='flex-1 w-full'>
            <label htmlFor="email" className='text-md font-medium text-dark-blue mb-2'>Email Address</label>
            <input
              type="email" id="email" className='block w-full rounded-md border-0 py-2 px-4 text-text-grey ring-1 ring-inset ring-light-blue placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dark-blue'
              value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div>
            <button type="submit" className='py-2 px-4 bg-light-blue text-white font-bold tracking-wide rounded-md'>
              Extract Slides
            </button>
          </div>
        </form>
        <p className="px-10 md:px-0 max-w-2xl mt-5 text-text-grey text-sm">The conversion process may take a few seconds, or upto an hour - depending on the video length and conversion items in the queue. We will email your PDF once it's converted. You can close the window at any time and we will email you the PDF. Or you can wait for the process to complete, the results will be displayed here.</p>
        {isLoading && <div className="w-full mt-4"><LinearProgress /></div>}
        {conversionMessage && <p className="px-10 md:px-0 max-w-2xl mt-5 text-lg bold text-blue">{conversionMessage}</p>}
        
        {videoSummary && (
          <div className='px-10 md:px-0 max-w-2xl mt-5 text-text-grey text-sm'>
            <p className="mb-4" dangerouslySetInnerHTML={{ __html: videoSummary }} />
          </div>
        )}
        
        
        {pdfBlobUrl && (
          <div className="mt-4 w-full max-w-2xl">
            <iframe src={pdfBlobUrl} style={{ width: '100%', height: '500px' }} title="PDF Preview"></iframe>
            <div className="flex justify-center mt-4 w-full">
              <a href={pdfBlobUrl} download="converted.pdf" className='block w-full py-2 px-4 bg-light-blue text-white font-bold tracking-wide rounded-md text-center'>
                Download PDF
              </a>
            </div>
          </div>
        )}
      </section>

         
      
      {examples}
      <br></br><br></br>
    </main>
  );
}

export default App;

